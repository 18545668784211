import PropTypes from "prop-types";
import { forwardRef, useCallback } from "react";
import classNames from "classnames";
import { reduxFormDomOnlyProps } from "app/utils/form/formUtils";
import HorizontalDotLoader from "app/pages/.shared/HorizontalDotLoader";
import { inputSanitize } from "app/utils/sanitize";
import "./Input.scss";

const Input = forwardRef(
	(
		{
			type = "text",
			label,
			name,
			id,
			field = {},
			disabled,
			isRequired = false,
			shouldSanitize = true, // dans le component AddressAutocomplete on met shouldSanitize = false pour reutiliser onChange de autocomplete
			loading = false,
			note = false,
			...props
		},
		ref
	) => {
		const inputClassName = classNames({
			"control-group": true,
			"control-group--required": isRequired,
			"control-group--touched": field.active || field.value || loading,
			"control-group--error": field.touched && field.error,
			"control-group--disabled": disabled,
			"control-group--loading": loading,
		});

		const handleChange = useCallback(event => {
			field.onChange(inputSanitize(event.target.value));
		}, []);

		return (
			<div className={inputClassName}>
				{loading ? (
					<div className="control-group__loader">
						<HorizontalDotLoader dotSize="3px" color="#3493dc" />
					</div>
				) : (
					<input
						ref={ref}
						{...reduxFormDomOnlyProps(field)}
						type={type}
						id={id}
						name={name}
						disabled={disabled}
						{...shouldSanitize && { onChange: handleChange }}
						{...props}
						className="control-group__input"
					/>
				)}
				<label htmlFor={id} className="control-group__label">
					{label}
				</label>
				{note && <div className="control-group__note"> {note}</div>}
			</div>
		);
	}
);

Input.propTypes = {
	name: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	note: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	isRequired: PropTypes.bool,
	shouldSanitize: PropTypes.bool,
	type: PropTypes.string,
	id: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
};

export default Input;
