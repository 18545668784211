import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import classNames from "classnames";
import IconTickboxTick from "app/pages/.shared/static/icons/IconTickboxTick";
import IconTickbox from "app/pages/.shared/static/icons/IconTickbox";
import "./checkbox.scss";

const CheckboxFormik = ({
	field,
	form,
	children,
	id,
	isRequired,
	disabled,
	onUpdate = () => {},
	grouped = true,
	...restProps
}) => {
	const { name, value: formikValue } = field;
	const { setFieldValue, setFieldTouched, touched, error } = form;

	const onChange = useCallback(
		event => {
			if (grouped) {
				const value = event.target.checked;
				const set = new Set(field.value);
				if (value) {
					set.add(restProps.value);
				} else {
					set.delete(restProps.value);
				}
				setFieldValue(field.name, Array.from(set));
				setFieldTouched(field.name, true);
				onUpdate(Array.from(set), field.name);
			} else {
				setFieldValue(field.name, event.target.checked);
			}
		},
		[field, restProps.value, formikValue]
	);

	const checked = grouped ? formikValue.includes(restProps.value) : formikValue;

	const checkboxClassName = classNames({
		checkbox: true,
		"checkbox--required": isRequired,
		"checkbox--error": touched && error,
		"checkbox--checked": field.value || checked,
		"checkbox--disabled": disabled,
	});

	const iconNode = (
		<div className="checkbox__icon">
			{checked ? (
				<IconTickboxTick height={20} width={20} />
			) : (
				<IconTickbox height={20} width={20} />
			)}
		</div>
	);

	return (
		<div className={checkboxClassName}>
			<input
				type="checkbox"
				checked={checked}
				id={id}
				name={name}
				className="checkbox__input"
				data-testid={restProps["data-testid"]}
				onChange={onChange}
				{...restProps}
			/>
			<label className="checkbox__label" htmlFor={id}>
				<div className="checkbox__header">
					{iconNode}
					<div className="checkbox__text">{children}</div>
				</div>
			</label>
		</div>
	);
};

CheckboxFormik.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	disabled: PropTypes.bool,
	isRequired: PropTypes.bool,
	name: PropTypes.string,
	id: PropTypes.string.isRequired,
	onUpdate: PropTypes.func,
	toggle: PropTypes.func,
	value: PropTypes.array,
	grouped: PropTypes.bool,
	"data-testid": PropTypes.string,
};

export default memo(CheckboxFormik);
