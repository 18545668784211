import { useCallback, memo } from "react";
import PropTypes from "prop-types";
import "./LogoBrand.scss";
import { brandPropTypes } from "app/utils/propTypes";
import { sendTagOnLogoRedirect } from "app/utils/analytics";

const LogoBrand = ({ shop = "", brand = "", isWhite = false, theme = {} }) => {
	const onClick = useCallback(() => {
		sendTagOnLogoRedirect();
	}, []);

	return theme?.logo ? (
		<img
			data-testid="logo-brand"
			onClick={onClick}
			src={theme?.logo}
			className={"logo-brand__logo"}
		/>
	) : (
		<span
			data-testid="logo-brand"
			onClick={onClick}
			className={`logo-brand logo-brand--${brand}-${shop}${isWhite ? "-white" : ""}`}
		/>
	);
};

LogoBrand.propTypes = {
	shop: PropTypes.string,
	isWhite: PropTypes.bool,
	brand: brandPropTypes,
	theme: PropTypes.object,
};

export default memo(LogoBrand);
