import { useEffect } from "react";
import { authSuccess, updateAuthToken } from "app/pages/Auth/AuthActions";
import { parseQueries } from "app/utils/routerUtils";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateStrategy } from "app/reducers/partnerActionCreators";
import PropTypes from "prop-types";

const OperatorRedirect = ({ updateStrategy, updateAuthToken, authSuccess }) => {
	const { search } = useLocation();
	const query = parseQueries(search);

	const { uuid, email, token, partner, redirectUrl, strategy } = query;

	useEffect(() => {
		// Pour les brands ayant une stack membre, on authentifie le user
		if (uuid && email && token && partner) {
			authSuccess({
				data: {
					uuid,
					email,
					token,
					partner: { code: partner },
				},
			});
		} else {
			updateAuthToken(token);
		}

		if (strategy) {
			updateStrategy(strategy);
		}
	}, [uuid, email, token, partner, strategy]);

	const pathname = redirectUrl && !redirectUrl.includes("://") ? redirectUrl : "/listing";

	const [redirectUrlPathname, redirectUrlSearch] = pathname.split("?");

	return (
		<Navigate
			to={{ pathname: redirectUrlPathname, search: redirectUrlSearch }}
			replace={true}
		/>
	);
};

OperatorRedirect.propTypes = {
	updateStrategy: PropTypes.func,
	updateAuthToken: PropTypes.func,
	authSuccess: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ updateStrategy, updateAuthToken, authSuccess }, dispatch);
};

export default connect(
	null,
	mapDispatchToProps
)(OperatorRedirect);
